import * as React from 'react';

import { isAdmin, isOpsAdmin } from './constants';
import { Page } from './types';
import { ComponentBox } from '../component/ComponentBox';

export const Home = () => {
  const showUserManagementLink = isOpsAdmin() || isAdmin();
  const showDiscountsLink = isOpsAdmin() || isAdmin();
  const showItemsManagementLink = isOpsAdmin();

  return (
    <div className="flex h-[calc(100vh-77px)] items-center justify-center bg-slate50">
      {showUserManagementLink && <ComponentBox title="Manage Users" page={Page.UserManagement} />}
      <ComponentBox title="Customer Lookup" page={Page.CustomerLookup} />
      {showDiscountsLink && <ComponentBox title="Manage Discounts" page={Page.DiscountsPage} />}
      {showItemsManagementLink && (
        <ComponentBox title="Manage Products & Items" page={Page.ManageDataPage} />
      )}
    </div>
  );
};
