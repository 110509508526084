import React, { useState, useMemo, useCallback } from 'react';

import { Pagination } from './Pagination';

interface TableProps<T> {
  header: string[];
  data: T[];
  pageSize?: number;
  paginatorsPerPageBlock?: number;
  internalPagination?: boolean;
  tdClass?: string;
}

const usePaginationPages = (
  data: any[],
  length: number,
  pageSize: number,
  paginatorsPerPageBlock = 5
) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(() => {
    return Math.ceil(length / pageSize);
  }, [length, pageSize]);

  const canGo = useMemo(() => {
    return {
      next: currentPage < totalPages,
      previous: currentPage - 1 > 0,
    };
  }, [currentPage, totalPages]);

  const pages = useMemo(() => {
    const start = Math.floor((currentPage - 1) / paginatorsPerPageBlock) * paginatorsPerPageBlock;
    const end =
      start + paginatorsPerPageBlock > totalPages ? totalPages : start + paginatorsPerPageBlock;
    return Array.from({ length: end - start }, (_, i) => start + i + 1);
  }, [currentPage, paginatorsPerPageBlock, totalPages]);

  const goTo = (pg: number) => {
    setCurrentPage(pg);
  };

  const goNext = useCallback(() => {
    if (canGo.next) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [canGo]);

  const goPrev = useCallback(() => {
    if (canGo.previous) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [canGo]);

  const currentData = data.slice((currentPage - 1) * pageSize, pageSize * currentPage);
  const renderPagination = data.length > pageSize;

  return {
    canGo,
    currentPage,
    pages,
    goTo,
    goNext,
    goPrev,
    currentData,
    renderPagination,
    countOfResults: length,
  };
};

export const Table = <T extends Record<string, any>>({
  header,
  data,
  pageSize = 25,
  paginatorsPerPageBlock,
  internalPagination = true,
  tdClass,
}: TableProps<T>) => {
  const { currentData, renderPagination, ...forPagination } = usePaginationPages(
    data,
    data.length,
    pageSize,
    paginatorsPerPageBlock
  );

  if (currentData.length) {
    return (
      <>
        <table className="min-w-full table-auto rounded border border-separate text-center">
          <thead className="bg-slate50">
            <tr>
              {header.map((column, index) => (
                <th key={index} className="px-4 py-2">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={index} className="bg-white">
                {header.map((column, colIndex) => (
                  <td key={colIndex} className={`px-4 py-2 ${tdClass}`}>
                    {Array.isArray(item[column]) ? (
                      <ul>
                        {item[column].map((listItem: string, listIndex: number) => (
                          <li key={String(listIndex)} className="whitespace-nowrap text-left">
                            {listItem}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      item[column]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {internalPagination && renderPagination && <Pagination {...forPagination} />}
      </>
    );
  }
  return null;
};
