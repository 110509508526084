import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Button } from './Button';
import { Close } from '../assets/Close';
import { InlineSpinner } from '../assets/Spinner';
import { SURVEY_OPTIONS, SubSurveyOption, SurveyOption } from '../exitSurveyOptions';
import { Customer, Pet } from '../pages/types';
import { cancelPetPlan, createSurveyResponse } from '../petApiUtils';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

enum CancelPetPlanStepper {
  StopPlanSurvey,
  StopPlanSubSurvey,
}

interface CancelPetPlanModalProps {
  petIdToCancel: string;
  customer: Customer;
  onCancel: () => void;
}

export const CancelPetPlanModal = ({
  petIdToCancel,
  customer,
  onCancel,
}: CancelPetPlanModalProps) => {
  const [step, setStep] = useState<CancelPetPlanStepper>(CancelPetPlanStepper.StopPlanSurvey);
  const [selectedPrimaryReason, setSelectedPrimaryReason] = useState<SurveyOption | null>(null);
  const [selectedSecondaryReason, setSelectedSecondaryReason] = useState<SubSurveyOption | null>(
    null
  );
  const [additionalComments, setAdditionalComments] = useState<string>('');
  const [isCancelLoading, setIsCancelLoading] = useState<boolean>(false);

  const pet = customer.pets.find((pet: Pet) => pet.id === petIdToCancel);
  const mealPetPlanProduct = pet?.pet_plan?.products?.find(
    (product: any) => product.product_type === 'MEAL' && product.status === 'ACTIVE'
  );
  const buttonLabel =
    step === CancelPetPlanStepper.StopPlanSurvey && selectedPrimaryReason?.subsurvey
      ? 'Continue'
      : 'Confirm Cancellation';

  const commentsRequired =
    selectedPrimaryReason?.label === 'I have a different issue not listed above' ||
    selectedSecondaryReason?.label === 'Other';

  const isButtonDisabled =
    !selectedPrimaryReason ||
    (step === CancelPetPlanStepper.StopPlanSubSurvey && !selectedSecondaryReason) ||
    (commentsRequired && !additionalComments);

  const handleConfirm = async () => {
    if (step === CancelPetPlanStepper.StopPlanSurvey && selectedPrimaryReason?.subsurvey) {
      setStep(CancelPetPlanStepper.StopPlanSubSurvey);
    } else {
      setIsCancelLoading(true);

      await createSurveyResponse({
        accountId: pet?.account,
        data: {
          primary_answer: selectedPrimaryReason?.label,
          secondary_answer: selectedSecondaryReason?.label,
          customer_comments: additionalComments,
          survey_tag: 'pet-plan-cancel-bark',
          pet_plan_product: mealPetPlanProduct?.id,
          product_code: mealPetPlanProduct?.code,
          pet_plan: pet?.pet_plan?.id,
          pet_plan_product_created_at: mealPetPlanProduct?.created,
          unit_price: mealPetPlanProduct?.unit_price,
        },
      })
        .then(async () => {
          await cancelPetPlan({
            petId: petIdToCancel,
            petStatus: selectedSecondaryReason?.passedAway ? 'DECEASED_TO_NOT_LIST' : undefined,
            primaryReason: selectedPrimaryReason,
            secondaryReason: selectedSecondaryReason,
            additionalComments,
          })
            .then(() => {
              toast.success('Pet plan cancelled successfully', BOTTOM_TOAST);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            })
            .catch((e) => {
              toast.error(`cancelPetPlan Error: ${generateErrorMsg(e)}`, BOTTOM_TOAST);
            });
        })
        .catch((e) => {
          toast.error(`createSurveyResponse Error: ${generateErrorMsg(e)}`, BOTTOM_TOAST);
        });

      setIsCancelLoading(false);
      onCancel();
    }
  };

  return (
    <div className="fixed inset-x-0 top-6 flex items-center justify-center z-50 modal">
      <div className="bg-white rounded-lg shadow-md p-4 w-[600px] relative border">
        <Close className="absolute top-1 right-1 w-8 h-8 cursor-pointer" onClick={onCancel} />
        {pet ? (
          <>
            <h3 className="text-lg font-semibold text-left">Cancel Plan</h3>
            <p className="text-sm text-left">
              <b>Customer</b>: {`${customer.first_name} ${customer.last_name} (${customer.email})`}
            </p>
            <p className="text-sm mb-2 text-left">
              <b>Dog</b>: {pet.name ?? 'Unknown'}
            </p>
            <hr />
            <div className="h-[70vh] overflow-y-auto mt-2">
              {step === CancelPetPlanStepper.StopPlanSurvey &&
                SURVEY_OPTIONS.map((option) => (
                  <div key={option.label}>
                    <label className="font-semibold flex items-center">
                      <input
                        className="m-2 self-start"
                        type="radio"
                        name="Primary Reason"
                        value={option.label}
                        checked={selectedPrimaryReason === option}
                        onChange={() => {
                          setSelectedPrimaryReason(option);
                        }}
                      />
                      {option.label}
                    </label>
                    {selectedPrimaryReason === option &&
                      !selectedPrimaryReason.subsurvey &&
                      !selectedPrimaryReason.hideCommentField && (
                        <div className="m-2 ml-[29px]">
                          <p className="text-sm text-left my-2 text-gray">
                            Please provide additional details{' '}
                            {commentsRequired ? '(required)' : '(optional)'}
                          </p>
                          <textarea
                            className="min-h-[100px] text-left border border-gray-300 rounded text-base p-2 w-full resize-none"
                            value={additionalComments}
                            onChange={(e) => setAdditionalComments(e.target.value)}
                          />
                        </div>
                      )}
                  </div>
                ))}
              {step === CancelPetPlanStepper.StopPlanSubSurvey &&
                selectedPrimaryReason &&
                selectedPrimaryReason.subsurvey?.map((option) => (
                  <div key={option.label}>
                    <label className="font-semibold">
                      <input
                        className="m-2"
                        type="radio"
                        name="Secondary Reason"
                        value={option.label}
                        checked={selectedSecondaryReason === option}
                        onChange={() => {
                          setSelectedSecondaryReason(option);
                        }}
                      />
                      {option.label}
                    </label>
                    {selectedSecondaryReason === option &&
                      !selectedSecondaryReason.hideCommentField && (
                        <div className="m-2 ml-[29px]">
                          <p className="text-sm text-left my-2 text-gray">
                            Please provide additional details{' '}
                            {commentsRequired ? '(required)' : '(optional)'}
                          </p>
                          <textarea
                            className="min-h-[100px] text-left border border-gray-300 rounded text-base p-2 w-full resize-none"
                            value={additionalComments}
                            onChange={(e) => setAdditionalComments(e.target.value)}
                          />
                        </div>
                      )}
                  </div>
                ))}
            </div>
            {/* CTA */}
            <div className="flex justify-center flex-col items-center mt-4">
              <Button
                variant="primary"
                label={isCancelLoading ? <InlineSpinner /> : buttonLabel}
                isDisabled={isButtonDisabled}
                onClick={handleConfirm}
              />
            </div>
          </>
        ) : (
          'PET NOT FOUND'
        )}
      </div>
    </div>
  );
};
