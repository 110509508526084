import * as React from 'react';

interface ButtonProps {
  label: string | React.JSX.Element;
  variant?: string;
  onClick: () => void;
  isDisabled?: boolean;
  className?: string;
  textClassName?: string;
}

const getBackgroundColor = (variant: string, isDisabled: boolean) => {
  if (isDisabled) {
    return 'bg-lightGray';
  }
  if (variant === 'primary') {
    return 'bg-primary';
  }
  if (variant === 'secondary') {
    return 'bg-secondary';
  }
  return '';
};

export const Button = ({
  label,
  onClick,
  variant = 'primary',
  isDisabled = false,
  className,
  textClassName,
}: ButtonProps) => {
  return (
    <button
      className={`${className} border border-gray-300 px-5 py-2 rounded min-w-[150px] ${getBackgroundColor(
        variant,
        isDisabled
      )}`}
      disabled={isDisabled}
      onClick={onClick}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div className={`text-black text-lg font-bold ${textClassName}`}>{label}</div>
    </button>
  );
};
