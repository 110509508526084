import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { axios } from '../api';
import { formatingOptions } from '../formatingOptions';
import { Option } from '../pages/types';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

export const useGetProducts = (
  requestType: 'one-time-order' | 'one-time-product' = 'one-time-order'
) => {
  const [products, setProducts] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const oneTimeOrderEndpointPart = '/products/codes';
  const oneTimeProductEndpointPart = '/products/one-time-codes';

  const apiEndpoint =
    requestType === 'one-time-order' ? oneTimeOrderEndpointPart : oneTimeProductEndpointPart;

  const getProducts = () => {
    axios
      .get(apiEndpoint)
      .then((response) => {
        setProducts(formatingOptions(response.data, { value: 'id', label: 'code' }));
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { products, isLoading };
};
