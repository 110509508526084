import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BarkUser } from './types';
import { API_URL } from '../api';
import { Button } from '../component/Button';
import { TextInput } from '../component/TextInput';
import { useAuth } from '../context/AuthContext';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

type LoginResponse = {
  account: BarkUser;
  token: string;
  token_created: boolean;
};

export const Login = () => {
  const [email, setEmailChange] = useState('');
  const [password, setPasswordChange] = useState('');
  const { login } = useAuth();

  const navigate = useNavigate();

  const handleLogin = () => {
    axios
      .post<LoginResponse>(
        `${API_URL()}/login`,
        {
          email,
          password,
        },
        {
          headers: {
            'x-application': 'BARK',
          },
        }
      )
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('account', JSON.stringify(response.data.account));
        localStorage.setItem('email', JSON.stringify(response.data.account.email));
        login();
        navigate('/');
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="flex h-screen items-center justify-center bg-slate50">
      <div className="grid gap-2 min-w-[300px]">
        <div className="flex justify-center mb-5">
          <img src={require('../assets/fullBarkLogo.png')} />
        </div>
        <TextInput label="Email" setTextChange={setEmailChange} />
        <TextInput variant="password" label="Password" setTextChange={setPasswordChange} />
        <Link to="/resetpassword" className="font-bold underline">
          Forgot Password?
        </Link>
        <div className="flex items-center justify-center mt-5">
          <Button
            label="Login"
            onClick={() => handleLogin()}
            isDisabled={email.length === 0 || password.length === 0}
          />
        </div>
      </div>
    </div>
  );
};
