import { ToastOptions } from 'react-toastify';

export const PET_PLAN_HOLD_STATUS_ERROR = 'Pet Plan has order on HOLD';

export const generateErrorMsg = (error: any) => {
  if (error?.response?.data) {
    return `Error: ${JSON.stringify(error.response.data)}`;
  }
  return 'Unable to perform the requested operation';
};

export const BOTTOM_TOAST: ToastOptions = {
  hideProgressBar: true,
  position: 'bottom-center',
};
