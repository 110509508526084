import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { axios } from '../api';
import { parseNumber } from '../financialUtils';
import {
  useOrderSummaryProductsForPayload,
  useOrderSummaryProductsForView,
} from '../orderSummaryUtils';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

import { Option } from '@/pages/types';

type getOrderSummaryProps = {
  customer: any;
  selectedProducts: Option[];
  selectedDog: any;
  discount_codes?: string[];
  isReplacement?: boolean;
  existingOrderProducts?: any[];
  dogs?: Option[];
  order_id?: string;
};

export type OrderProductType = {
  product_code: string;
  quantity: number;
  pet_plan: string;
  price?: string;
};

export const useGetOrderSummary = ({
  customer,
  selectedProducts,
  selectedDog,
  discount_codes = [],
  isReplacement = false,
  existingOrderProducts = [],
  dogs = [],
  order_id = undefined,
}: getOrderSummaryProps) => {
  const petPlan = useMemo(
    () => customer.petplans.find((petPlan: any) => petPlan.pet === selectedDog.value),
    [customer.petplans, selectedDog.value]
  );

  const [summary, setSummary] = useState<any>();
  const [triggerRequest, setTriggerRequest] = useState(0);
  const [error, setError] = useState(null);

  const reCallOrderSummary = () => {
    setTriggerRequest((prev) => prev + 1);
  };

  const [orderProducts, setOrderProducts] = useState<OrderProductType[]>(
    selectedProducts.map((product: any) => {
      return {
        product_code: product.label,
        quantity: 1,
        pet_plan: petPlan.id,
        type: isReplacement ? 'REPLACEMENT' : 'ONETIME',
      };
    })
  );

  const formattedExistingProductsForPayload =
    useOrderSummaryProductsForPayload(existingOrderProducts);
  const formattedExistingProductsForView = useOrderSummaryProductsForView(
    selectedDog,
    dogs,
    existingOrderProducts
  );

  const getSummary = (customer: any, orderProducts: any) => {
    axios
      .post(`orders?dry_run=true`, {
        account: customer.id,
        order_products: [...orderProducts, ...formattedExistingProductsForPayload],
        order_type: 'ONETIME',
        discount_codes,
        order_id,
      })
      .then((response) => {
        setSummary(response.data);
        const { order_products } = response.data;
        // Adding price for each one-time product from summary response
        setOrderProducts((prev) =>
          prev.map((product) => {
            const productWithPrice = order_products.filter(
              (el: any) => el.product_code === product.product_code
            )[0];
            return {
              ...product,
              price: (
                parseNumber(productWithPrice.unit_price) * parseNumber(productWithPrice.quantity)
              ).toFixed(2),
            };
          })
        );
        setError(null);
      })
      .catch((e) => {
        setError(e?.response?.data);
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  useEffect(() => {
    getSummary(customer, orderProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customer.id,
    orderProducts.length,
    selectedDog.value,
    discount_codes.length,
    triggerRequest,
    customer,
  ]);

  return {
    summary: {
      ...summary,
      summaryProducts: {
        products: formattedExistingProductsForView,
        existingOrderProducts: formattedExistingProductsForPayload,
        doesExist: existingOrderProducts.length > 0,
      },
    },
    orderProducts,
    discount_codes: summary?.discount_codes || [],
    setOrderProducts,
    reCallOrderSummary,
    error,
  } as {
    summary: any;
    discount_codes: string[];
    orderProducts: OrderProductType[];
    setOrderProducts: React.Dispatch<React.SetStateAction<OrderProductType[]>>;
    reCallOrderSummary: () => void;
    error: any;
  };
};
