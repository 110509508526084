import Axios from 'axios';

export let buildData = {
  FE_GH_COMMIT_HASH: 'N/A',
  FE_BUILD_DATE: 'N/A',
};
try {
  buildData = require('../buildData.json');
} catch (e) {
  console.error('Build Data is not present, run yarn build to correct the issue', e);
}

export const PROD_URL = 'https://snt-production-backend.herokuapp.com';
export const STAGE_URL = 'https://snt-staging-backend.herokuapp.com';
export const DEV_URL = 'https://dev1-api.spotandtango.com';
export const LOCAL_URL = 'http://127.0.0.1:8000';

// TODO: Only call this function once
// This config dynamically sets the URL for the env
export const API_URL = () => {
  const url = new URL(window.location.href);
  const subdomain = url.hostname.split('.')[0];

  if (subdomain === 'localhost') {
    return LOCAL_URL;
  }
  if (subdomain === 'dev-bark') {
    return DEV_URL;
  }
  if (subdomain === 'stage-bark') {
    return STAGE_URL;
  }
  if (subdomain === 'bark') {
    return PROD_URL;
  }

  throw new Error('Invalid domain name');
};

export const axios = Axios.create({
  baseURL: API_URL(),
});

axios.interceptors.request.use(function authRequestInterceptor(config) {
  const auth_token = localStorage.getItem('token');
  if (auth_token && config.headers) {
    config.headers['Authorization'] = `Token ${auth_token}`;
  }
  if (config.headers) {
    config.headers['x-application'] = 'BARK';
    config.headers['x-fe-gh-commit-hash'] = buildData.FE_GH_COMMIT_HASH;
    config.headers['x-fe-build-date'] = buildData.FE_BUILD_DATE;
  }
  return config;
});
