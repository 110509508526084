import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { BarkUser, Group, UserGroup } from './types';
import { axios } from '../api';
import { Spinner } from '../assets/Spinner';
import { Button } from '../component/Button';
import { DeactivateUser } from '../component/DeactivateUser';
import { SendPasswordResetLink } from '../component/SendPasswordResetLink';
import { Table } from '../component/Table';
import { formatDateTime } from '../dateUtils';
import { UserManagementHeaders } from '../tableHeaders';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

const groupNameMapper = (group: string) => {
  if (group === UserGroup.OpsAdmin) {
    return 'Ops Admin';
  }
  if (group === UserGroup.Admin) {
    return 'Admin';
  }
  if (group === UserGroup.TeamMember) {
    return 'Team Member';
  }
  return 'Unsupported Group';
};

const displayGroup = (groups: Group[]) =>
  groups.map((group: Group) => groupNameMapper(group.name)).join(', ');

export const UserManagement = () => {
  const [data, setData] = useState<BarkUser[] | null>(null);
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = () => {
    axios
      .get(`bark_user`)
      .then((response) => {
        setData(response.data.map((user: BarkUser) => mapAccountToBarkUser(user)));
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  const handleUserDeactivated = () => {
    fetchUsers();
  };

  const mapAccountToBarkUser = (user: BarkUser) => {
    return {
      Name: `${user.first_name} ${user.last_name}`,
      Email: <SendPasswordResetLink label={user.email} />,
      'User Group': `${displayGroup(user.groups)}`,
      Added: `${formatDateTime(user.created)}`,
      'Last Updated': `${formatDateTime(user.last_updated)}`,
      Status: (
        <DeactivateUser
          isActive={user.is_active}
          email={user.email}
          handleUserDeactivated={handleUserDeactivated}
        />
      ),
    };
  };

  if (data === null) {
    return <Spinner />;
  }

  return (
    <div className="relative h-[calc(100vh-77px)] bg-slate50 p-8">
      <div className="flex items-center justify-between mb-3">
        <h1>Manage Users</h1>
        <Button
          label="Create User"
          onClick={() => {
            location.href = '/user-management/create-user';
          }}
        />
      </div>
      <Table header={UserManagementHeaders} data={data} />
    </div>
  );
};
