export enum Page {
  Login = 'login',
  ForgotPassword = 'forgot-password',
  CreateUser = 'create-user',
  UserManagement = 'user-management',
  CustomerLookup = 'customer-lookup',
  DiscountsPage = 'discounts',
  ManageDataPage = 'manage-data',
  ItemsPage = 'items',
  ProductItemsPage = 'product-items',
}

export type Group = {
  id: string;
  name: string;
};

export enum UserGroup {
  Admin = 'BarkAdmin',
  TeamMember = 'BarkTeamMember',
  OpsAdmin = 'BarkOpsAdmin',
}

export type BarkUser = {
  first_name: string;
  last_name: string;
  email: string;
  groups: Group[];
  is_active: string;
  created: string;
  last_updated: string;
};

export enum PetStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  CANCELLED = 'CANCELLED',
  DECEASED = 'DECEASED',
  DECEASED_TO_NOT_LIST = 'DECEASED_TO_NOT_LIST',
  UKNOWN = 'UNKNOWN',
}

export type Pet = {
  id: string;
  name: string;
  status: PetStatus;
  gender: string;
  birth_month?: string;
  birth_year?: string;
  breeds?: string[];
  properties?: any;
  pet_plan?: PetPlan;
  account?: string;
};
export type PetPlan = {
  account: string;
  id: string;
  calories_per_day: number;
  discount_amt: number;
  discounted_total_price: number;
  frequency: number;
  is_topper: boolean;
  last_updated: string;
  next_charged_date: string;
  pet: string;
  plan_size: number;
  products: PetPlanProduct[];
  recipe_data: Recipe[];
  recipes: string[];
  start_date: string;
  status: string;
  status_date: string;
  total_price: number;
};

export type PetPlanProduct = {
  id: string;
  code: string;
  created: string;
  frequency: number;
  name: string;
  next_charge_date: string;
  price_per_week: string;
  product_id: string;
  product_type: string;
  quantity: number;
  recipes: Recipe[];
  status: string;
  type: string;
  unit_price: string;
};

export type Order = {
  id: string;
  account: string;
  total_price: string | null;
  total_refunds: string;
  discounts: OrderDiscount[];
  charged: string | null;
  status: OrderStatus;
  order_type: OrderType;
  pricing_model: PricingModel | null;
};

export enum OrderStatus {
  PREVIEW = 'PREVIEW',
  PROCESSING = 'PROCESSING',
  CHARGING = 'CHARGING',
  MAX_RETRY_REACHED = 'MAX_RETRY_REACHED',
  READY_FOR_FULFILLMENT = 'READY_FOR_FULFILLMENT',
  FULFILLING = 'FULFILLING',
  PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
  FULFILLED = 'FULFILLED',
  COMPLETE = 'COMPLETE',
  HOLD = 'HOLD',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  HISTORICAL = 'HISTORICAL',
  RUSH_ORDER = 'RUSH_ORDER',
}

export enum OrderType {
  TRIAL = 'TRIAL',
  SUBSCRIPTION = 'SUBSCRIPTION',
  ONETIME = 'ONETIME',
  REPLACEMENT = 'REPLACEMENT',
}

export type Orders = {
  past: Order[];
  processing: Order[];
  upcoming: Order[];
};

export type Customer = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  pets: Pet[];
  created: string;
  last_updated: string;
  phone_number: string;
  orders: Orders;
  status?: string;
  fulfillments: any[]; // TODO: Define this as a Bark Type
  petplans: any; // TODO: Define this as a Bark Type
  survey_responses: any[]; // TODO: Define this as a Bark Type
  default_pricing_model: PricingModel | null;
};

export type Address = {
  id: string;
  account: string;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  latitude: string | null;
  longitude: string | null;
  is_primary: boolean;
  type: 'SHIPPING' | 'BILLING';
  shipping_name: string | null;
};

export type Option = {
  value: string;
  label: string;
  hasBadge?: boolean; //Can be used if need to show badge
};

export enum ChangelogOperation {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export type CustomerChangelogItem = {
  id: string;
  application: string | null;
  accountId: string;
  table: string;
  fieldName: string;
  newValue: string;
  petId: string | null;
  operationType: ChangelogOperation;
  authorEmail: string;
  rowPrimaryKey: string;
  createdAt: string;
};

export type SystemChangelogItem = {
  category: string;
  objectId: string;
  application: string | null;
  createdAt: string;
  fieldName: string;
  newValue: string;
  operationType: ChangelogOperation;
  authorEmail: string;
};

export type ChangelogItem = CustomerChangelogItem | SystemChangelogItem;

export type OrderDiscount = {
  id: string;
  discount: Discount;
  amount: string;
  created: string;
  order: string;
  status: string;
};

export type Discount = {
  value: string;
  order_onetime: boolean;
  order_trial: boolean;
  order_subscription: boolean;
  name: string;
  limit_type: string;
  limit_value: string;
  discount_type: string;
  available_end_date: string;
  available_start_date: string;
  code: string;
  id: string;
  is_active: boolean;
  created: string;
};

export enum DiscountExclusiveType {
  WINBACK_10_PERCENT = 'WINBACK_10_PERCENT',
  WINBACK_30_PERCENT = 'WINBACK_30_PERCENT',
}

export type Treatment = {
  feature_flag: string;
  feature_flag_status: string;
  treatment_distribution: number;
  treatment_control: boolean;
  treatment_name: string;
  created: string;
};

export type Item = {
  id: string;
  sku: string;
  name: string;
  recipe: string;
  weight_oz: number;
  volume: number;
};
export type Recipe = {
  id: string;
  description: string;
  name: string;
  seq_no: number;
  type: string;
};

export type ProductRelatedItem = {
  sku: string;
  quantity: number;
  id?: string; // This is only used in the UI, unique key for the list
};

export type ProductItem = {
  id: string;
  code: string;
  name: string;
  status: string;
  items: ProductRelatedItem[];
};

export type PricingModel = {
  created: string;
  creator: string | null;
  description?: string;
  id: string;
  is_default: boolean;
  last_updated: string;
  name: string;
  status: string;
};
