import * as React from 'react';
import { Link } from 'react-router-dom';

import { DatabaseIcon } from '../assets/DatabaseIcon';
import { DiscountIcon } from '../assets/DiscountIcon';
import { Search } from '../assets/Search';
import { User } from '../assets/User';
import { Page } from '../pages/types';

interface ComponentBoxProps {
  title: string;
  page: Page;
}

const isHomePageItem = (page: Page) => {
  return (
    page === Page.UserManagement ||
    page === Page.CustomerLookup ||
    page === Page.DiscountsPage ||
    page === Page.ManageDataPage
  );
};
export const ComponentBox = ({ title, page }: ComponentBoxProps) => {
  if (!isHomePageItem(page)) {
    return <>This page is not supported!</>;
  }

  return (
    <Link to={page}>
      <div className="m-3 p-20 w-80 h-80 rounded-md shadow-md cursor-pointer bg-secondary border">
        {page === Page.UserManagement && <User className="mx-auto w-24 h-24 mb-4" />}
        {page === Page.CustomerLookup && <Search className="mx-auto w-24 h-24 mb-4" />}
        {page === Page.DiscountsPage && <DiscountIcon className="mx-auto w-24 h-24 mb-4" />}
        {page === Page.ManageDataPage && <DatabaseIcon className="mx-auto w-24 h-24 mb-4" />}
        <h2 className="text-xl font-semibold text-center pt-2">{title}</h2>
      </div>
    </Link>
  );
};
