import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { OneTimeOrderContext } from '.';
import { axios } from '../../api';
import { digitsOptions } from '../../dropdownOptions';
import { useGetOrderSummary, OrderProductType } from '../../hooks/getOrderSummary';
import { Option } from '../../pages/types';
import { BOTTOM_TOAST, generateErrorMsg } from '../../toastUtils';
import { Button } from '../Button';
import { ConfirmationModal } from '../ConfirmationModal';
import { Dropdown } from '../Dropdown';
import { OrderSummary } from '../OrderSummary';

export const OneTimeOrderStep2 = () => {
  const {
    customer,
    selectedProducts,
    selectedDog,
    setSelectedProducts,
    navigateBetweenSteps,
    isEverythingSelected,
    paymentMethod,
    onConfirm,
  } = useContext(OneTimeOrderContext);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [discountCodes, setDiscountCodes] = useState<string[]>([]);

  const { summary, orderProducts, setOrderProducts, reCallOrderSummary, discount_codes, error } =
    useGetOrderSummary({
      customer,
      selectedProducts,
      selectedDog,
      discount_codes: discountCodes,
    });

  const deleteProduct = (code: string) => {
    setSelectedProducts((prev: Option[]) => prev.filter((item) => item.label !== code));
    setOrderProducts((prev) => prev.filter((item) => item.product_code !== code));
  };

  const updateQuantityProduct = (code: string, option: Option) => {
    setOrderProducts((prev) =>
      prev.map((item) => (item.product_code === code ? { ...item, quantity: +option.value } : item))
    );
    reCallOrderSummary();
  };

  useEffect(() => {
    navigateBetweenSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEverythingSelected]);

  // Check if bad request connected to discount_codes, set discount_codes empty.
  useEffect(() => {
    if (error?.discount_codes) {
      setDiscountCodes([]);
    }
  }, [error?.discount_codes]);

  const onCreate = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    axios
      .post(`orders`, {
        account: customer.id,
        order_products: orderProducts,
        discount_codes: discountCodes,
        order_type: 'ONETIME',
      })
      .then(() => {
        setShowConfirmModal(false);
        onConfirm();
        toast.success(
          `
          We processed a one-time-order and charged $${summary.total_price}
          to the customer's default payment method (${paymentMethod})`,
          BOTTOM_TOAST
        );
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="bg-white mt-6 p-6 pb-2 max-w-[1000px] w-[100%] border border-lightGray relative">
        <h2 className="font-bold text-left mb-2">Order Summary</h2>
        <h2 className="font-bold mb-2 text-center">{selectedDog.label}</h2>
        <div className="flex flex-col">
          {orderProducts.map((product: OrderProductType) => (
            <div key={product.product_code} className="pt-1 pb-1 flex items-center">
              <div className="w-[70px] mr-[10px]">
                <Dropdown
                  options={digitsOptions}
                  onSelect={(newSelection) =>
                    updateQuantityProduct(product.product_code, newSelection)
                  }
                />
              </div>
              {product.product_code}
              <a
                className="cursor-pointer text-red-600 pl-[2px] pr-[8px]"
                onClick={() => deleteProduct(product.product_code)}
              >
                X
              </a>
            </div>
          ))}
        </div>
        <div className="w-full h-[1px] bg-lightGray mt-2 mb-3" />
        <OrderSummary
          summary={summary}
          discountCodes={discount_codes}
          setDiscountCodes={setDiscountCodes}
        />
      </div>
      <div className="mt-[30px] w-fill flex justify-center">
        <Button
          label="Create One-Time Order"
          variant="primary"
          onClick={() => setShowConfirmModal(true)}
        />
      </div>
      {showConfirmModal && (
        <ConfirmationModal
          title="Are you sure you want to proceed with creating this one-time order?"
          message={`The customer's default payment method (${paymentMethod}) will be charged immediately.`}
          confirmLabel="Confirm"
          onConfirm={onCreate}
          isProcessLoading={isLoading}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};
