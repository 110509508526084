import { UserGroup } from './pages/types';

export const userGroupOptions = [
  {
    label: 'Admin',
    value: UserGroup.Admin,
  },
  {
    label: 'Team Member',
    value: UserGroup.TeamMember,
  },
  {
    label: 'Ops Admin',
    value: UserGroup.OpsAdmin,
  },
];

export const mealOptions = [
  {
    label: 'UnKibble',
    value: 'UNKIBBLE',
  },
  {
    label: 'Fresh',
    value: 'FRESH',
  },
];

const startYear = new Date().getFullYear();
const yearsArray = Array.from({ length: 24 }, (_, i) => startYear - i); // count backwards 24 years from startYear
export const yearOptions = yearsArray.map((year) => ({ label: String(year), value: String(year) }));

export const genderOptions = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
];

export const booleanOptions = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export const monthOptions = [
  {
    label: 'January',
    value: '1',
  },
  {
    label: 'February',
    value: '2',
  },
  {
    label: 'March',
    value: '3',
  },
  {
    label: 'April',
    value: '4',
  },
  {
    label: 'May',
    value: '5',
  },
  {
    label: 'June',
    value: '6',
  },
  {
    label: 'July',
    value: '7',
  },
  {
    label: 'August',
    value: '8',
  },
  {
    label: 'September',
    value: '9',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
];

export const digitsOptions = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
];
