import * as React from 'react';
import { useContext } from 'react';

import { OneTimeProductContext } from '.';
import { InlineSpinner } from '../../assets/Spinner';
import { Button } from '../Button';
import { Dropdown } from '../Dropdown';
import { MultiSelectDropdown } from '../MultiSelectDropdown';
export const OneTimeProductStep1 = () => {
  const {
    dogs,
    setSelectedDog,
    products,
    setSelectedProducts,
    isLoading,
    isEverythingSelected,
    navigateBetweenSteps,
    selectedDog,
  } = useContext(OneTimeProductContext);
  return (
    <>
      <div className="w-9/12 pr-2 pt-3">
        <div className="text-md font-semibold text-left">
          <h3>Which dog would you like to add a one-time product for?</h3>
        </div>
        <div className="mb-2">
          <Dropdown
            options={dogs}
            selectedOption={selectedDog || { value: '', label: 'Select dog' }}
            onSelect={(newSelection) => setSelectedDog(newSelection)}
          />
        </div>
      </div>
      <div className="w-9/12 pr-2">
        <div className="text-md font-semibold text-left">
          <h3>Which one-time product should we include in this dog's next Order?</h3>
        </div>
        <div className="mb-2 flex relative">
          <MultiSelectDropdown
            options={products}
            onSelect={setSelectedProducts}
            placeholder="Start typing a product code"
            selectedOptionAsObject
          />
          {isLoading && (
            <div className="absolute inset-0 bg-black/10 flex items-center justify-center">
              <InlineSpinner />
            </div>
          )}
        </div>
      </div>
      <div className="mt-[45px] w-fill flex justify-center">
        <Button
          isDisabled={!isEverythingSelected}
          label="Continue"
          variant={isEverythingSelected ? 'primary' : 'secondary'}
          onClick={navigateBetweenSteps}
        />
      </div>
    </>
  );
};
