import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isAdmin, isOpsAdmin } from './constants';
import { UserGroup } from './types';
import { axios } from '../api';
import { Button } from '../component/Button';
import { Dropdown } from '../component/Dropdown';
import { TextInput } from '../component/TextInput';
import { userGroupOptions } from '../dropdownOptions';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

export const CreateUser = () => {
  const [selected, setSelected] = useState(userGroupOptions[0]);
  const [firstName, setFirstNameChange] = useState('');
  const [lastName, setLastNameChange] = useState('');
  const [email, setEmailChange] = useState('');
  const navigate = useNavigate();

  const filteredUserGroupOptions = userGroupOptions.filter((option) => {
    switch (option.value) {
      case UserGroup.TeamMember:
        return isAdmin() || isOpsAdmin();
      case UserGroup.Admin:
        return isAdmin() || isOpsAdmin();
      case UserGroup.OpsAdmin:
        return isOpsAdmin();
      default:
        return false;
    }
  });

  const handleCreateUser = () => {
    if (email.includes('+bark@')) {
      axios
        .post(`bark_user`, {
          first_name: firstName,
          last_name: lastName,
          email,
          is_active: true,
          group_name: selected.value,
        })
        .then(() => {
          navigate('/user-management');
          toast.success(`Successfully created bark user ${firstName} ${lastName}`, BOTTOM_TOAST);
        })
        .catch((e) => {
          toast.error(generateErrorMsg(e), BOTTOM_TOAST);
        });
    } else {
      toast.error(
        'Validation error: all Bark users must have +bark qualifier in their email address',
        BOTTOM_TOAST
      );
    }
  };

  return (
    <div className="flex h-[calc(100vh-77px)] overflow-auto items-center justify-center bg-slate50">
      <div className="grid gap-2 min-w-[300px]">
        <h1>Manage Users: Add New User</h1>
        <TextInput label="First Name" setTextChange={setFirstNameChange} />
        <TextInput label="Last Name" setTextChange={setLastNameChange} />
        <TextInput label="Email" setTextChange={setEmailChange} />
        <Dropdown
          title="User Group"
          options={filteredUserGroupOptions}
          onSelect={(newSelection) => setSelected(newSelection)}
        />
        <div className="flex mt-5 items-center justify-center">
          <Button label="Add User" variant="primary" onClick={() => handleCreateUser()} />
        </div>
      </div>
    </div>
  );
};
