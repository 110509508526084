import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ConfirmationModal } from './ConfirmationModal';
import { axios } from '../api';
import { Pencil } from '../assets/Pencil';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

interface DeactivateUserProps {
  isActive: string;
  email: string;
  handleUserDeactivated: () => void;
}

export const DeactivateUser = ({ isActive, email, handleUserDeactivated }: DeactivateUserProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleDeactivateBarkUser = () => {
    axios
      .put(`bark_user`, {
        email,
        is_active: false,
      })
      .then(() => {
        toast.info(`${email} is now a deactivated BARK user.`, BOTTOM_TOAST);
        setShowModal(false);
        handleUserDeactivated();
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="flex justify-between items-center">
      <div className="inline">{isActive ? 'Active' : 'Deactivated'}</div>
      <Pencil className="w-5 h-5 cursor-pointer" onClick={() => setShowModal(true)} />
      {showModal && (
        <ConfirmationModal
          title={`Deactivate BARK user ${email}?`}
          message="This action cannot be undone."
          confirmLabel="Deactivate"
          onConfirm={() => handleDeactivateBarkUser()}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
